import React from "react";

const Checkbox = ({ value, setValue }) => {
	const handleChange = () => {
		setValue((prev) => !prev);
	};

	return (
		<div className="flex items-center mt-6">
			<input
				id="default-checkbox"
				type="checkbox"
				checked={value}
				onChange={handleChange}
				className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input>
			<label
				htmlFor="default-checkbox"
				className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
				Want 80G Receipt
			</label>
		</div>
	);
};

export default Checkbox;
