
import './App.css';

import Navbar from './components/Navbar';
import HomePage from './views/Homepage/HomePage';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorPage from './components/ErrorPage';
import Checkout from './views/Checkout/Checkout';

const router = createBrowserRouter([
    {
        path: "/",
        element: <HomePage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/checkout/:price",
        element: <Checkout />,
        errorElement: <ErrorPage />,
    },

]);

function App() {
    return (
        <div className="pb-24">
            <Navbar />
            <RouterProvider router={router} />
        </div>
    )



}

export default App;
