import React, { useState } from "react";
import Input from "../../components/UI/Input";
import { useParams } from "react-router-dom";
import apiClient from "../../utils/apiClient";
import Checkbox from "../../components/UI/Checkbox";

const Checkout = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [pin, setPin] = useState("");
	const [phone, setPhone] = useState("");
	const [pan, setPan] = useState("");
	const [panRequired, setPanRequired] = useState(false);

	const params = useParams();

	const getOrderId = () => {
		const refno = new Date().getTime();
		apiClient
			.get("/update_goseva23t.php", {
				params: {
					donationdata: `${params.price}|${name}|${address}|${city}|${state}|${pin}|${phone}|${email}|0|${refno}`,
				},
			})
			.then((response) => {
				displayRazorpay(response.data);
			});
	};

	function loadScript(src) {
		return new Promise((resolve) => {
			const script = document.createElement("script");
			script.src = src;
			script.onload = () => {
				resolve(true);
			};
			script.onerror = () => {
				resolve(false);
			};
			document.body.appendChild(script);
		});
	}

	async function displayRazorpay(order_id) {
		const res = await loadScript(
			"https://checkout.razorpay.com/v1/checkout.js"
		);

		if (!res) {
			alert("Razorpay SDK failed to load. Are you online?");
			return;
		}

		let options = {
			key: "rzp_test_TufrWAmUYWXbrI", // Enter the Key ID generated from the Dashboard
			amount: params.price * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
			currency: "INR",
			name: "ISKCON Newtown",
			description: "Go Seva",
			image:
				"https://www.janmastami.iskconnewtown.com/wp-content/uploads/2020/07/logifinal.png",
			order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
			// "callback_url": "https://www.backend.iskconnewtown.com/update_annadanam.php",
			prefill: {
				name: name,
				email: email,
				contact: phone,
			},
			notes: { address: "Razorpay Corporate Office" },
			theme: { color: "#FED03D" },
			modal: {
				ondismiss: function () {
					alert("hello");
				},
			},
			handler: function (response) {
				console.log("123", response);

				const params = new URLSearchParams();
				params.append(
					"pdata",
					`${response.razorpay_payment_id}|${response.razorpay_order_id}|${response.razorpay_signature}`
				);

				apiClient
					.post("/update_goseva23t.php", params)
					.then((response) => {
						console.log(response);
					})
					.catch((error) => {
						console.log(error);
					});
			},
		};
		const paymentObject = new window.Razorpay(options);
		paymentObject.open();
	}

	const formDetails = [
		{
			label: "Name",
			id: "name",
			type: "text",
			placeholder: "Enter name",
			required: true,
			value: name,
			setValue: setName,
		},
		{
			label: "Street address",
			id: "street",
			type: "text",
			placeholder: "House number and street name",
			required: true,
			value: address,

			setValue: setAddress,
		},
		{
			label: "Town / City",
			id: "city",
			type: "text",
			placeholder: "Enter city",
			required: true,
			value: city,
			setValue: setCity,
		},
		{
			label: "State",
			id: "state",
			type: "select",
			placeholder: "Enter state",
			required: true,
			value: state,
			setValue: setState,
		},
		{
			label: "PIN Code",
			id: "pin",
			type: "text",
			placeholder: "Enter PIN",
			required: true,
			value: pin,
			setValue: setPin,
		},
		{
			label: "Phone",
			id: "phone",
			type: "tel",
			placeholder: "Enter phone no",
			required: true,
			value: phone,
			setValue: setPhone,
		},
		{
			label: "Email address",
			id: "email",
			type: "email",
			placeholder: "Enter email address",
			required: true,
			value: email,
			setValue: setEmail,
		},
	];

	const panDetails = [
		{
			label: "Pan no",
			id: "pan",
			type: "pan",
			placeholder: "Enter pan no",
			required: true,
			value: pan,
			setValue: setPan,
		},
	];

	return (
		<div className="flex flex-col items-center mt-10">
			<div className="text-4xl">Proceed to Donation</div>
			<div className="p-10 flex w-[85%] gap-6">
				<div className="w-full flex flex-col">
					<div className="px-2 py-2 text-xl text-white font-bold bg-[#b22222]">
						DONOR INFORMATION
					</div>
					{formDetails.map((item, index) => {
						return (
							<Input
								key={index}
								label={item.label}
								id={item.id}
								type={item.type}
								placeholder={item.placeholder}
								required={item.required}
								value={item.value}
								setValue={item.setValue}
							/>
						);
					})}
					{<Checkbox value={panRequired} setValue={setPanRequired} />}

					{panRequired &&
						panDetails.map((item, index) => {
							return (
								<Input
									key={index}
									label={item.label}
									id={item.id}
									type={item.type}
									placeholder={item.placeholder}
									required={item.required}
									value={item.value}
									setValue={item.setValue}
								/>
							);
						})}
				</div>
				<div className="w-full flex flex-col">
					<div className="px-2 py-2 text-xl text-white font-bold bg-[#b22222]">
						DONATION DETAILS
					</div>
					<div className="flex px-4">
						<div className="mt-12">ISKCON Charity Donation Amount :</div>
						<div className="mt-12 ml-auto font-bold">₹ {params.price}</div>
					</div>
					<div className="flex px-12">
						<button
							onClick={getOrderId}
							className="mt-10 self-center bg-[#fd6c11] hover:bg-[#cd2653] w-full rounded p-2 text-white text-base font-bold">
							DONATE NOW
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Checkout;
