import React from "react";
import logo from "../static/logo.jpeg";

const Navbar = () => {
	return (
		<div className="h-20 flex px-16 py-2 items-center shadow-lg">
			<a href="/">
				<img className="h-20" src={logo} alt="" />
			</a>
		</div>
	);
};

export default Navbar;
