import React from "react";

const Banner2 = () => {
	return (
		<>
			<div className="w-screen px-52 bg-black h-52 flex justify-center items-center ">
				<span className="text-3xl font-bold text-center text-white">
					Gentle Request! While doing Paytm or Bank (NEFT/ RTGS) please send us
					screen shot on our Whatsapp No- +91-7439186878 You may also call on
					this no for other queries
				</span>
			</div>
		</>
	);
};

export default Banner2;
