import React from "react";
import DonateCard from "./DonateCard";
import DonateRow from "./DonateRow";
import Banner from "./Banner";
import Banner2 from "./Banner2";

const HomePage = () => {
	const donationData = [
		{
			numMeals: 'a cow for 5',
			price: 600,
		},
		{
			numMeals: 'a cow for 10',
			price: 1200,
		},
		{
			numMeals: 'a cow for 15',
			price: 1800,
		},
		{
			numMeals: 'a cow for 30',
			price: 3600,
		},
	];

	const donationTableData = [
		{
			numMeals: 'all cow for 1',
			price: 2500,
		},
		{
			numMeals: 'all cow for 5',
			price: 12500,
		},
		{
			numMeals: 'all cow for 10',
			price: 25000,
		},
		{
			numMeals: 'all cow for 15',
			price: 37500,
		},
		{
			numMeals: 'all cow for 30',
			price: 75000,
		},
	];

	return (
		<>
			<Banner />
			<div className="w-screen px-52 py-16">
				<div className="flex justify-center text-4xl">
					Donate to Serve Mother Cow
				</div>
			</div>

			<div className="w-[1100px] m-auto">
				<div className="w-1/1 py-2 grid grid-cols-2 grid-rows-2 gap-y-[30px] gap-x-[30px]">
					<div className="row-start-1 row-end-2 col-start-1 col-end-2 flex flex-wrap gap-[20px]">
						{donationData.map((data) => {
							return <DonateCard numMeals={data.numMeals} price={data.price} />;
						})}
					</div>
					<div className="row-start-2 row-end-3 col-start-1 col-end-2">
						<div className="flex flex-col">
							{donationTableData.map((data) => {
								return (
									<DonateRow numMeals={data.numMeals} price={data.price} />
								);
							})}
						</div>
					</div>
					<div className="row-start-1 row-end-3 col-start-2 col-end-3">
						<div className="flex flex-col">
							<div className="p-2 bg-black text-white text-center font-bold text-xl rounded">
								Donation Through Bank (NEFT/ RTGS)
							</div>
							<div className="text-base font-sans font-extrabold mt-3">
								Bank Name - RBL Bank
							</div>
							<div className="text-base font-sans font-extrabold">
								Account Name - ISKCON{" "}
							</div>
							<div className="text-base font-sans font-extrabold">
								Account Number – 309007220511{" "}
							</div>
							<div className="text-base font-sans font-extrabold">
								IFSC Code - RATN0000167{" "}
							</div>
							<hr className="text-black border-gray-700 border-[1px] mt-2 mb-4" />

							<div className="text-[#fd6c11] text-[19px] font-medium">
								80G Available As Per Income Tax Act 1961 And Rules Made
								Thereunder
							</div>
							<div className="text-base font-bold mt-3">
								Income Tax Exemption (80-G) Number AAATI0017PF20219
							</div>
							<hr className="text-black border-gray-700 border-[1px] mt-2 mb-4" />
							<div className="text-[#fd6c11] text-[19px] font-medium">
								80G Benefits Cannot Be Availed On Paytm Donations Except Paytm
								UPI Transfer
							</div>
						</div>
					</div>
				</div>
			</div>

			<Banner2 />
		</>
	);
};

export default HomePage;
