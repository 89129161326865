import React from "react";
import { Link } from "react-router-dom";

const DonateCard = ({ numMeals, price, link }) => {
	return (
		<div className="w-[calc(50%-10px)] border-2 border-solid border-black p-4 rounded-2xl flex flex-col items-center ">
			<div className="inline-block text-center text-lg font-bold mb-0 pb-2 uppercase duration-100 ease-in-out text-[#282525]]">
				Feed {numMeals} day
			</div>
			<div className="font-semibold text-3xl flex">₹ {price}</div>
			<Link
				to={`/checkout/${price}`}
				target="_blank"
				rel="noopener noreferrer"
				className="bg-[#e6b745] hover:bg-[#fd6c11] hover:text-white transition-all duration-300 text-black border-0 text-lg font-extrabold rounded-full py-2 px-6 flex mt-2">
				DONATE NOW
			</Link>
		</div>
	);
};

export default DonateCard;
