import React from "react";
import banner from "../../static/banner.jpg";

const Banner = () => {
	return (
		<>
			<img className="w-screen" src={banner} alt="" />
			<div className="w-screen px-52 bg-[#e6b745] h-52 flex justify-center items-center ">
				<span className="text-3xl font-bold text-center">
					We request you to Join our hands to support Goseva &
					Invoke all auspiciousness in your life.
				</span>
			</div>
		</>
	);
};

export default Banner;
