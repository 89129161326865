import React from "react";
import { Link } from "react-router-dom";

const DonateRow = ({ numMeals, price, link }) => {
	return (
		<div className="flex w-full justify-between items-center">
			<div className="inline-block text-center text-lg font-bold mb-0 py-2 uppercase duration-100 ease-in-out text-[#282525]] border-r-2 border-[#e9bf5b] pl-2 pr-2 flex items-center w-[50%]">
				<div className="py-1">Feed {numMeals} days</div>
			</div>
			<div className="inline-block text-center text-lg border-r-2 mb-0 py-2 border-[#e9bf5b] w-[20%] pr-8 text-[#282525] whitespace-nowrap h-full">
				<div className="py-1">₹ {price}</div>
			</div>

			<div className="py-2">
				<Link
					to={`/checkout/${price}`}
					target="_blank"
					rel="noopener noreferrer"
					className="bg-[#1a1512] px-2 py-1 text-white rounded w-[30%] whitespace-nowrap">
					DONATE NOW
				</Link>
			</div>
		</div>
	);
};

export default DonateRow;
